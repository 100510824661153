import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';

export interface ButtonProps {
  children: ReactNode;
  className?: string;
  variant?: 'standard' | 'outlined';
  size?: 'small' | 'medium' | 'large';
  type?: 'button' | 'submit' | 'reset';
  to?: string;
  href?: string;
}

export default function Button({
  children,
  className,
  variant = 'standard',
  size = 'large',
  type = 'button',
  to,
  href,
}: ButtonProps) {
  const classes = clsx(
    'inline-block',
    'font-bold text-white tracking-wide whitespace-nowrap',
    'rounded-full uppercase rounded shadow hover:shadow-lg outline-none focus:outline-none focus:ring',
    {
      'border-solid border-2 border-transparent bg-blue hover:bg-blue-dark': variant === 'standard',
      'border-solid border-2 border-gray-100 hover:bg-blue hover:border-blue': variant === 'outlined',
      'text-md md:text-xl px-6 py-3 md:px-10 md:py-5': size === 'large',
      'text-md px-6 py-3': size === 'medium',
      'text-xs sm:text-sm px-5 py-3 sm:px-6 sm:py-3': size === 'small',
    },
    className
  );

  const style = { transition: 'all .15s ease' };

  if (to) {
    return (
      <Link className={classes} style={style} to={to}>
        {children}
      </Link>
    );
  }

  if (href) {
    return (
      <a className={classes} style={style} href={href}>
        {children}
      </a>
    );
  }

  return (
    <button className={classes} style={style} type={type}>
      {children}
    </button>
  );
}
