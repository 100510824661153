import React from 'react';

import Button from '../components/Button';
import Layout from '../components/Layout';

export default function Kiitos() {
  return (
    <Layout>
      <div className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen bg-gray-900">
        <div className="container relative">
          <div className="items-center flex flex-wrap">
            <div className="w-full px-2 md:px-6 text-center">
              <h1 className="text-blue font-display text-5xl md:text-8xl mb-4">Kiitos yhteydenotosta!</h1>

              <p className="text-blue font-display text-xl md:text-4xl mb-8">
                <span className="px-3 my-1 tracking-wide leading-tight uppercase">
                  Palaamme asiaan mahdollisimman pian.
                </span>
              </p>

              <Button size="small" to="/">
                <div className="flex flex-row items-center">
                  <div className="mr-2">Takaisin etusivulle</div>
                  <div className="text-white" aria-hidden style={{ width: 24, height: 24 }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
